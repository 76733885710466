import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';
import { Router } from '@angular/router';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/services/common.service';

@Injectable()
export class HttpconfigInterceptor implements HttpInterceptor {

  private refreshingInProgress: boolean = false;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private APIUrl: string = environment.baseUrl;

  constructor(
    private auth: AuthorizationService,
    private router: Router,
    private commonService: CommonService
  ) {}
/**
 * const loaderService = this.injector.get(LoaderService);

loaderService.show();

return next.handle(req).pipe(
  delay(5000),
  finalize(() => loaderService.hide())
);
 */
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      const accessToken = this.auth.currentUserValue?.token;
      let excludeUrls = [this.APIUrl + '/login'];
      console.log(req);
      console.log("exclude url",excludeUrls);
      this.commonService.setLoadingState(true);
      if(!excludeUrls.includes(req.url))
      {
        console.log("if");

        return next.handle(this.addAuthorizationHeader(req, accessToken)).pipe(
        finalize(() => this.commonService.setLoadingState(false)),
        catchError(err => {
          console.log("in error");
          let excludeErrorUrl = [this.APIUrl + '/logout'];
          if(!excludeErrorUrl.includes(req.url)){
          console.log("in error if");

            // in case of 401 http error
            if (err instanceof HttpErrorResponse && err.status === 401) {

              // get refresh tokens
              const refreshToken =  this.auth.currentUserValue?.refresh_token;

              // if there are tokens then send refresh token request
              if (refreshToken && accessToken) {
                return this.refreshToken(req, next);
              }

              // otherwise logout and redirect to login page
              return this.logoutAndRedirect(err);
            }

            // in case of 403 http error (refresh token failed)
            if (err instanceof HttpErrorResponse && err.status === 403) {
              // logout and redirect to login page
              return this.logoutAndRedirect(err);
            }
          }
          console.log("in error else");
          // if error has status neither 401 nor 403 then just return this error
          return throwError(err);
        })
      );
    }
    else{
      console.log("else");

      return next.handle(req).pipe(
        finalize(() => this.commonService.setLoadingState(false))
      );
    }
  }

    private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
      if (token) {
        return request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
      }

      return request;
    }

    private logoutAndRedirect(err: HttpErrorResponse): Observable<HttpEvent<any>> {
      // this.auth.logout().subscribe(data=>{
      //   this.auth.setCurrentUser(null);
      //   this.router.navigate(['/account/login']);
      // },
      // error=>{
      //   this.auth.setCurrentUser(null);
      //   this.router.navigate(['/account/login']);
      // });

      return throwError(err);
    }

    private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (!this.refreshingInProgress) {
        this.refreshingInProgress = true;
        this.accessTokenSubject.next('');

        return this.auth.refreshToken().pipe(
          switchMap((data:any) => {
            let user = this.auth.currentUserValue;
            user.token = data.access_token;
            user.refresh_token = data.refresh_token;
            this.auth.setCurrentUser(user);
            this.refreshingInProgress = false;
            this.accessTokenSubject.next(data.access_token);

            // repeat failed request with new token
            return next.handle(this.addAuthorizationHeader(request, data.access_token));
          })
        );
      } else {
        // wait while getting new token
        return this.accessTokenSubject.pipe(
          filter(token => token !== null),
          take(1),
          switchMap(token => {
            // repeat failed request with new token
            return next.handle(this.addAuthorizationHeader(request, token));
          }));
      }
    }



}
