import {BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {
  config: any;
  onClose: any;

  constructor(
    public modal: BsModalRef
    ) {

   }

  ngOnInit() {
  }

  public decline() {
    this.modal.hide();
    this.onClose(false);
  }

  public accept() {
    this.modal.hide();
    this.onClose(true);
  }
}
