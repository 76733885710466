import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private loadingState = new BehaviorSubject<boolean>(false);
  loadingState$ = this.loadingState.asObservable();

  constructor() { }

  setLoadingState(value: boolean) {
    this.loadingState.next(value);
    console.log(this.loadingState.value);
  }

}
