<div class="modal-content text-center no-border">
  <div class="modal-header d-flex justify-content-center no-border">
      <i class="fas fa-3x fa-check" style="color: green;"></i>
  </div>
  <div class="modal-body no-border">
      <h5 class="modal-title" id="exampleModalLabel">
        <div *ngIf="config && config.title">{{ config.title }}</div>
      </h5>
      <span *ngIf="config && config.message">{{ config.message }}</span>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button type="button" class="btn btn-outline-success" data-mdb-dismiss="modal" (click)="accept()">
      OK
    </button>
  </div>
</div>
