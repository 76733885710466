import {BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-popup-modal',
  templateUrl: './error-popup-modal.component.html',
  styleUrls: ['./error-popup-modal.component.css']
})
export class ErrorPopupModalComponent implements OnInit {

  config: any;
  onClose: any;

  constructor(
    public modal: BsModalRef
    ) {

   }

  ngOnInit() {
  }

  public accept() {
    this.modal.hide();
    this.onClose(true);
  }

}
