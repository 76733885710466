import {BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-success-popup-modal',
  templateUrl: './success-popup-modal.component.html',
  styleUrls: ['./success-popup-modal.component.css']
})
export class SuccessPopupModalComponent implements OnInit {
  config: any;
  onClose: any;

  constructor(
    public modal: BsModalRef
    ) {

   }

  ngOnInit() {
  }

  public accept() {
    this.modal.hide();
    this.onClose(true);
  }
}
