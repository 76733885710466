import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmationModalComponent } from './shared/common/messages/confirmation-modal/confirmation-modal.component';
import { SuccessPopupModalComponent } from './shared/common/messages/success-popup-modal/success-popup-modal.component';
import { ErrorPopupModalComponent } from './shared/common/messages/error-popup-modal/error-popup-modal.component';
import { HttpconfigInterceptor } from './shared/interceptors/httpconfig.interceptor';
import { LoaderComponent } from './shared/common/loader/loader.component';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationModalComponent,
    SuccessPopupModalComponent,
    ErrorPopupModalComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      positionClass :'toast-bottom-right'
    }),
    HttpClientModule
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: HttpconfigInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
