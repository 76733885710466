import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ErrorPopupModalComponent } from './error-popup-modal/error-popup-modal.component';
import { SuccessPopupModalComponent } from './success-popup-modal/success-popup-modal.component';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  bsModalRef!: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private router: Router
  ) { }

  info(message: string, title?: string){

  };

  success(message: string, title: string, callback: (result: boolean) => any){
    this.bsModalRef = this.modalService.show(SuccessPopupModalComponent, {class: 'modal-md', ignoreBackdropClick: true});
    this.bsModalRef.content.config = {
      title : title,
      message : message
    }
    this.bsModalRef.content.onClose = (data: any) => {
      callback(true);
    };
  }


  warn(message: string, title?: string){

  }
  error(message: string, title?: string, callback?: (result: boolean) => any){
    this.bsModalRef = this.modalService.show(ErrorPopupModalComponent, {class: 'modal-md', ignoreBackdropClick: true});
    this.bsModalRef.content.config = {
      title : title,
      message : message
    }
    this.bsModalRef.content.onClose = (data: any) => {
      if(callback)
        callback(data);
    };
  }
  confirm(message: string, title?:string, callback?: (result: boolean) => void){
    this.bsModalRef = this.modalService.show(ConfirmationModalComponent, {class: 'modal-md', ignoreBackdropClick: true});
    this.bsModalRef.content.config = {
      title : title,
      message : message,
      btnOkText:'OK',
      btnCancelText:'Cancel'
    }
    this.bsModalRef.content.onClose = (data: any) => {
      if(callback)
        callback(data);
    };
  }
}
