import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MessageService } from '../common/messages/message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private authorizationUrl: string = environment.baseUrl;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser;

  constructor(
    private http: HttpClient,
    private router: Router,
    private message: MessageService
  ) {
    const user = localStorage.getItem('currentUser') || '';
    this.currentUserSubject = new BehaviorSubject<any>(user ? JSON.parse(user) : null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  setCurrentUser(user: any) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  login(username: String,password: String,returnUrl: string){
    let authorizationData = 'Basic ' + btoa(username + ':' + password);
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': authorizationData
      })
    };
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.authorizationUrl + '/login', httpOptions).subscribe(
        data=>{
            if(data.status === 'Success'){
              let jwt_token = data.authResponse.jwt_token;
              let refresh_token = data.authResponse.refresh_token;
              httpOptions = {
                headers: new HttpHeaders({
                  'Content-Type':  'application/json',
                  'Authorization': 'Bearer ' + jwt_token
                })
              };
              return this.http.get(this.authorizationUrl + '/user', httpOptions).subscribe((data:any)=>{
                let user: any = data.data;
                user.token = jwt_token;
                user.refresh_token = refresh_token;
                if(user.role === 'N')
                  user.role = 'tenant';
                else
                  user.role = 'superAdmin';
                this.setCurrentUser(user);
                this.currentUserSubject.next(user);
                let url;
                if(user.role === 'tenant')
                  url = returnUrl || 'admin/user/dashboard';
                else if(user.role === 'superAdmin')
                  url = returnUrl || 'admin/main/dashboard';
                this.router.navigate([url]);
                return user;
              });
            }
            return data;
        },
        error=>{
          let errorMsg = error?.error?.message ? error.error.message : 'Error' ;
          this.message.error(errorMsg);
        });
    });
  }

  logout() {
    return this.http.get(this.authorizationUrl + '/logout');
  }

  refreshToken() {
    const refreshToken = this.currentUserValue?.refresh_token;
    return this.http.post(this.authorizationUrl + '/token/refresh',{refresh_token: refreshToken});
  }

}
