import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

const routes: Routes = [
  { path: '', redirectTo: '/admin/user/dashboard', pathMatch: 'full' },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), data: { preload: true } },
  { path: '**', redirectTo: '/admin/user/dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [BsModalService],
})
export class AppRoutingModule { }
