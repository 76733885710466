import { Component, OnInit } from '@angular/core';
import { CommonService } from './shared/common/services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'TitanFrontend';
  isLoading: boolean = false;

  constructor(
    private commonService: CommonService
  ){}

  ngOnInit(){
    console.log("in app comp init");

    this.commonService.loadingState$.subscribe(value =>{
      console.log(value)
      this.isLoading = value;
    })
  }

}
